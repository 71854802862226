
.App { 
    padding: 0px 0px;
}

.LoginCard { 
    padding: 20vh
}

.Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

@media (max-width:1200px) {
    .BiCard-title {
        font-size: 18px;
    }
    .BiCard-text {
        font-size: 15px;
    }
}

.CardFooter {
    font-size: 14px;
}

.buttonBIWAPP {
    background-color: #24d366; /* Green */
    border: 2px solid #24d366; /* Green */
    color: #ffffff;
    transition-duration: 0.4s;
  }
  
.buttonBIWAPP:hover {
    background-color: #1caf55; /* Green */
    border: 2px solid #1caf55; /* Green */
    color: #ffffff;
}

.buttonBIWAPP:disabled {
    background-color: #1caf55; /* Green */
    border: 2px solid #1caf55; /* Green */
    color: #ffffff;
    opacity: 0.6;
    cursor: not-allowed;
}

.buttonSideBar {
    background-color: #222529; /* Gray */
    border: 1px solid #c8c8c9; /* Gray */
    color: #c8c8c9;
    transition-duration: 0.4s;
  }
  
.buttonSideBar:hover {
    background-color: #2f3235; /* Green */
    border: 1px solid #ffffff; /* Green */
    color: #ffffff;
}

.buttonSideBar:disabled {
    background-color: #222529; /* Gray */
    border: 1px solid #ffffff; /* Gray */
    color: #ffffff;
  }

